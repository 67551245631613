<template>
  <v-app class="background">
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'IntroLayout'
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.background {
  background-color: #fff !important;
  background-image: url('../assets/intro-bg.jpg') !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}
</style>
